import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import styled from "styled-components";

import Navbar from "../components/Navbar";
import { register } from "../store/authSlice";
import Spinner from "../components/Spinner";
import { mobile } from "../utils/responsive";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("https://img.freepik.com/free-vector/accident-scene-with-car-crash-city_1308-37541.jpg?w=826&t=st=1669948716~exp=1669949316~hmac=b80dbe8f4e2e257bdacf82d1816ed309b2ee570c1ac98beb535fdca9139406e0")
      center;
  background-size: cover;
`;

const UpperWrapper = styled.div`
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  background-color: white;
  width: 30%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  ${mobile({ width: "70%", padding: "15px" })}
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 20px;
  ${mobile({ fontSize: "18px" })}
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Input = styled.input`
  flex: 1;
  min-width: 90%;
  margin: 10px 10px 10px 0;
  padding: 10px;
  font: small-caption;
  font-size: 16px;
  ${mobile({ width: "80%", fontSize: "12px" })}
`;

const ErrorMessage = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: red;
  ${mobile({ fontSize: "10px" })}
`;

const Button = styled.input`
  font-size: 18px;
  font-weight: 400;
  background-color: teal;
  color: white;
  border: none;
  width: 40%;
  padding: 15px 20px;
  margin-top: 20px;
  cursor: pointer;
  ${mobile({ fontSize: "14px", padding: "5px 10px" })}
`;

const Link = styled.a`
  margin-top: 20px;
  font-size: 12px;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
  ${mobile({ fontSize: "10px" })}
`;

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const changeNameHandler = (e) => {
    setName(e.target.value);
  };
  const changeEmailHandler = (e) => {
    setEmail(e.target.value);
  };
  const changePhoneHandler = (e) => {
    setPhone(e.target.value);
  };
  const changePasswordHandler = (e) => {
    setPassword(e.target.value);
  };

  const userData = {
    name,
    email,
    phone,
    password,
  };

  const loginHandler = () => {
    navigate("/login");
  };

  const registerHandler = async (e, userData) => {
    e.preventDefault();
    await dispatch(register(userData));
  };

  useEffect(() => {
    if (user) {
      navigate("/user");
      // console.log("user ----------->", user);
    }
  }, [navigate, user]);

  return (
    <Container>
      <Navbar />
      <UpperWrapper>
        <Wrapper>
          <Title>CREATE YOUR ACCOUNT</Title>
          <Form onSubmit={(e) => registerHandler(e, userData)}>
            <Input
              placeholder="name"
              value={name}
              onChange={changeNameHandler}
            ></Input>

            <Input
              placeholder="phone (+201000000000)"
              type="tel"
              value={phone}
              onChange={changePhoneHandler}
            ></Input>
            <Input
              placeholder="email"
              type="email"
              value={email}
              onChange={changeEmailHandler}
            ></Input>
            <Input
              placeholder="password"
              type="password"
              value={password}
              onChange={changePasswordHandler}
            ></Input>

            {isError && <ErrorMessage>{message}</ErrorMessage>}

            <Button type="submit" value="CREATE" />
          </Form>
          <Link onClick={loginHandler}>YOU ALREADY HAVE AN ACCOUNT</Link>
        </Wrapper>
      </UpperWrapper>
      {isLoading && <Spinner />}
    </Container>
  );
};

export default Register;
