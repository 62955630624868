import styled from "styled-components";
import logo from "../images/logo2.jpeg";
import cover from "../images/cover2.jpeg";
import { Badge } from "@material-ui/core";
import { Notifications } from "@material-ui/icons";
import { mobile } from "../utils/responsive";

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url(${cover}) center;
  background-size: cover;
  width: 100vw;
`;

const UpperWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
`;

const LogoContainer = styled.div`
  /* height: 70%;
  width: 8%; */
`;

const Logo = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  ${mobile({ width: "70px", height: "70px" })}
`;
const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Name = styled.h3`
  margin: 5px 0;
  font-size: 30px;
  color: white;
  background-color: teal;
  border-radius: 10px;
  padding: 10px 20px;
  text-align: center;
  ${mobile({ fontSize: "16px", padding: "5px 10px" })}
`;

const PopList = styled.div`
  position: absolute;
  left: calc(-125px + 50%);
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  padding: 20px 10px;
  display: none;
  z-index: 10000;

  ${mobile({ width: "180px", padding: "10px 5px" })}
`;

const NotificationContainer = styled.div`
  margin-right: 60px;
  cursor: pointer;
  position: relative;
  transition: all 1s ease-in-out;

  &:hover ${PopList} {
    display: flex;
  }
`;

const PopDisaster = styled.a`
  width: 80%;
  background-color: #f50057;
  padding: 10px;
  border-radius: 10px;
  margin: 5px 0;
  color: white;
  text-decoration: none;
`;

const PopName = styled.p`
  ${mobile({ fontSize: "14px" })}
`;
const PopTime = styled.p`
  ${mobile({ fontSize: "12px" })}
`;
const PopDate = styled.p`
  ${mobile({ fontSize: "12px" })}
`;

const PopDisasterNone = styled.div`
  width: 80%;
  background-color: #0f6cbd;
  padding: 10px;
  border-radius: 10px;
  margin: 5px 0;
`;

const LowerWrapper = styled.div`
  height: 60vh;
`;

const RescuProfile = ({ isDisaster, setIsDisaster, disasters, user }) => {
  const notificationHandler = () => {
    setIsDisaster(!isDisaster);
  };

  return (
    <ProfileContainer>
      <UpperWrapper>
        <LogoContainer>
          {/* <Logo src={`http://localhost:8080/public/img/users/${user.photo}`} /> */}
          <Logo
            src={`https://risk-management-server.onrender.com/public/img/users/${user.photo}`}
          />
        </LogoContainer>

        <Info>
          <Name>{user.name.toUpperCase()}</Name>
        </Info>
        <NotificationContainer onClick={notificationHandler}>
          <Badge
            badgeContent={disasters?.length}
            color="secondary"
            overlap="circular"
          >
            <Notifications color="inherit" fontSize="large" />
          </Badge>
          <PopList>
            {disasters?.map((disaster) => (
              <PopDisaster key={disaster._id} href={`#${disaster._id}`}>
                <PopName>{disaster.name}</PopName>
                <PopTime>{disaster.time}</PopTime>
                <PopDate>{disaster.date}</PopDate>
              </PopDisaster>
            ))}

            {disasters.length === 0 && (
              <PopDisasterNone>
                <PopName>No Disaster Found</PopName>
              </PopDisasterNone>
            )}
          </PopList>
        </NotificationContainer>
      </UpperWrapper>
      {!isDisaster && <LowerWrapper />}
    </ProfileContainer>
  );
};

export default RescuProfile;
