import styled from "styled-components";
import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { createDisaster } from "../store/disasterSlice";
import Spinner from "../components/Spinner";
import Dropzone from "react-dropzone";
import { Box, Button, Typography } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { mobile } from "../utils/responsive";
import { useEffect } from "react";
import { updateMe } from "../store/authSlice";

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("https://img.freepik.com/free-vector/accident-scene-with-car-crash-city_1308-37541.jpg?w=826&t=st=1669948716~exp=1669949316~hmac=b80dbe8f4e2e257bdacf82d1816ed309b2ee570c1ac98beb535fdca9139406e0")
      center;
  background-size: cover;
  width: 100vw;
  position: relative;
`;

const UpperWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
`;

const LogoContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

const Logo = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;

  ${mobile({ width: "70px", height: "70px" })}
`;

const ImageForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`;

const ReportButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
  font-size: 30px;
  color: white;
  background-color: red;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  cursor: pointer;
  ${mobile({ fontSize: "14px", padding: "5px 10px", borderRadius: "5px" })}
`;

const WelcomeContainer = styled.div`
  position: relative;
  transition: all 1s ease-in-out;
`;

const Welcome = styled.h2`
  ${mobile({ fontSize: "12px" })}
`;

const LowerWrapper = styled.div`
  height: 70vh;
`;
const DisasterWrapper = styled.div`
  position: absolute;
  background-color: white;
  width: 40%;
  left: 30%;
  top: 150px;
  border: 2px solid teal;
  border-radius: 10px;
  padding: 10px 0;
  ${mobile({ width: "80%", left: "10%" })}
`;
const Title = styled.h2`
  text-align: center;
  padding: 10px;
  border-bottom: 2px solid teal;
  margin-bottom: 20px;
  ${mobile({ fontSize: "16px" })}
`;

const DisasterKind = styled.div`
  padding: 10px;
`;
const KindTitle = styled.h4`
  margin-bottom: 20px;
  ${mobile({ fontSize: "14px" })}
`;

const KindsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;
const Kind = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid red;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 16px;
  width: 35%;
  margin: 5px 15px;
  cursor: pointer;
  background-color: ${(props) => props.choose && "lightblue"};

  &:hover {
    background-color: lightgray;
  }

  ${mobile({ fontSize: "12px", margin: "5px" })}
`;

const ErrorMessage = styled.p`
  font-weight: 500;
  font-size: 18px;
  color: red;
  padding: 0 20px;

  ${mobile({ fontSize: "12px" })}
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
`;

const MyButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 5px;
  font-size: 24px;
  font-weight: 400;
  padding: 5px 15px;
  background-color: ${(prpos) => prpos.color};
  color: white;
  cursor: pointer;
  ${mobile({ fontSize: "16px" })}
`;

const UserProfile = ({ isSent, setIsSent, user, token }) => {
  const dispatch = useDispatch();
  const { isLoading, isSuccess } = useSelector((state) => state.disaster);
  const isLoadingPhoto = useSelector((state) => state.auth.isLoading);

  const [isEditingPhoto, setIsEditingPhoto] = useState(false);
  const [photo, setPhoto] = useState("");

  const [isSending, setIsSending] = useState(false);
  const [isAvailable, setIsAvailable] = useState(true);

  const [isFire, setIsFire] = useState(false);
  const [isFlood, setIsFlood] = useState(false);
  const [isLeakage, setIsLeakage] = useState(false);
  const [isDrowning, setIsDrowning] = useState(false);
  const [isEarthquake, setIsEarthquake] = useState(false);
  const [isAccident, setIsAccident] = useState(false);

  const sendingHandler = () => {
    setIsEditingPhoto(false);
    setIsSending(true);
    setIsSent(false);
  };

  const chooseAccidentHandler = () => {
    setIsAccident(!isAccident);
  };
  const chooseLeakageHandler = () => {
    setIsLeakage(!isLeakage);
  };
  const chooseDrowningHandler = () => {
    setIsDrowning(!isDrowning);
  };
  const chooseEarthquakeHandler = () => {
    setIsEarthquake(!isEarthquake);
  };
  const chooseFloodHandler = () => {
    setIsFlood(!isFlood);
  };
  const chooseFireHandler = () => {
    setIsFire(!isFire);
  };

  const cancelButtonHandler = () => {
    setIsEditingPhoto(false);

    setIsSending(false);
    setIsAccident(false);
    setIsLeakage(false);
    setIsDrowning(false);
    setIsEarthquake(false);
    setIsFlood(false);
    setIsFire(false);
  };

  function getUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          // console.log(
          //   `https://www.google.com/maps/@${lat},${lng},10z`,
          //   `https://www.google.com/maps/dir/${lat},${lng}/31.293988,30.7899148/@31.1328464,30.9345536,13z/data=!4m5!4m4!1m1!4e1!1m0!3e2`
          // );
          // console.log(lat, lng);

          localStorage.setItem("userLat", lat);
          localStorage.setItem("userLng", lng);
        },
        () => {
          alert("Could not get your current position");
        }
      );
    }
  }
  getUserLocation();

  if (!isAvailable) {
    setTimeout(() => {
      setIsAvailable(true);
    }, 2000);
  }

  const sendButtonHandler = async () => {
    setIsEditingPhoto(false);

    if (
      !isFire &&
      !isLeakage &&
      !isFlood &&
      !isEarthquake &&
      !isDrowning &&
      !isAccident
    ) {
      setIsAvailable(false);
      return;
    }
    const disasterData = {
      flame: isFire,
      gas: isLeakage,
      flood: isFlood,
      earthquake: isEarthquake,
      drowning: isDrowning,
      accident: isAccident,
      lat: localStorage.getItem("userLat"),
      lng: localStorage.getItem("userLng"),
    };

    await dispatch(createDisaster(disasterData));

    setIsSent(true);
    setIsSending(false);
    setIsAccident(false);
    setIsLeakage(false);
    setIsDrowning(false);
    setIsEarthquake(false);
    setIsFlood(false);
    setIsFire(false);
  };

  const editPhotoHandler = async (e) => {
    e.preventDefault();

    let data = new FormData();
    data.append("photo", photo);

    await dispatch(updateMe({ token, data }));
    setPhoto("");
    setIsEditingPhoto(false);
  };

  const [logoSrc, setLogoSrc] = useState(
    // `http://localhost:8080/public/img/users/${user.photo}`
    `https://risk-management-server.onrender.com/public/img/users/${user.photo}`
  );
  useEffect(() => {
    // setLogoSrc(`http://localhost:8080/public/img/users/${user.photo}`);
    setLogoSrc(
      `https://risk-management-server.onrender.com/public/img/users/${user.photo}`
    );
  }, [user]);

  return (
    <ProfileContainer>
      <UpperWrapper>
        <LogoContainer>
          <Logo
            // src={`http://localhost:8080/public/img/users/${user.photo}`}
            // src={`https://risk-management-server.onrender.com/public/img/users/${user.photo}`}
            src={logoSrc}
            onClick={() => setIsEditingPhoto(!isEditingPhoto)}
          />

          {isEditingPhoto && (
            <Box
              width="150px"
              backgroundColor="white"
              color="black"
              position="absolute"
              border={`1px solid grey`}
              borderRadius="5px"
              padding="1rem"
            >
              <ImageForm onSubmit={editPhotoHandler}>
                <Dropzone
                  acceptedFiles=".jpg,.jpeg,.png"
                  multiple={false}
                  onDrop={(acceptedFiles) => setPhoto(acceptedFiles[0])}
                >
                  {({ getRootProps, getInputProps }) => (
                    <Box
                      {...getRootProps()}
                      sx={{
                        width: "100%",
                        // backgroundColor: "grey",
                        marginBottom: "1rem",
                        "&:hover": { cursor: "pointer" },
                      }}
                    >
                      <input {...getInputProps()} />
                      {!photo ? (
                        <p>Edit Your Photo</p>
                      ) : (
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography>{photo.name}</Typography>
                          <EditOutlinedIcon />
                        </Box>
                      )}
                    </Box>
                  )}
                </Dropzone>

                <Button
                  type="submit"
                  variant="contained"
                  disabled={!photo}
                  size="small"
                >
                  Update
                </Button>
              </ImageForm>
            </Box>
          )}
        </LogoContainer>

        <ReportButton onClick={sendingHandler}>REPORT DISASTER</ReportButton>
        <WelcomeContainer>
          <Welcome>
            Welcome,{" "}
            <b style={{ color: "red" }}>
              {user?.name.split(" ")[0].toUpperCase()}
            </b>
          </Welcome>
        </WelcomeContainer>
      </UpperWrapper>
      <LowerWrapper></LowerWrapper>
      {isSending && (
        <DisasterWrapper>
          <Title>Disaster from your location</Title>
          <DisasterKind>
            <KindTitle>Choose the disaster you find</KindTitle>
            <KindsContainer>
              <Kind onClick={chooseAccidentHandler} choose={isAccident}>
                Accident
              </Kind>
              <Kind onClick={chooseLeakageHandler} choose={isLeakage}>
                Petroleum Fluid Leakage
              </Kind>
              <Kind onClick={chooseDrowningHandler} choose={isDrowning}>
                Drowning
              </Kind>
              <Kind onClick={chooseEarthquakeHandler} choose={isEarthquake}>
                Earthquake
              </Kind>
              <Kind onClick={chooseFloodHandler} choose={isFlood}>
                Flood
              </Kind>
              <Kind onClick={chooseFireHandler} choose={isFire}>
                Fire
              </Kind>
            </KindsContainer>
          </DisasterKind>
          {!isAvailable && (
            <ErrorMessage>Kindly choose the disaster</ErrorMessage>
          )}
          <ButtonsContainer>
            <MyButton color="red" onClick={cancelButtonHandler}>
              Cancel
            </MyButton>
            <MyButton color="green" onClick={sendButtonHandler}>
              Send
            </MyButton>
          </ButtonsContainer>
        </DisasterWrapper>
      )}
      {(isLoading || isLoadingPhoto) && <Spinner />}
    </ProfileContainer>
  );
};

export default UserProfile;
