import styled from "styled-components";

import Navbar from "../components/Navbar";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    url("https://img.freepik.com/free-vector/accident-scene-with-car-crash-city_1308-37541.jpg?w=826&t=st=1669948716~exp=1669949316~hmac=b80dbe8f4e2e257bdacf82d1816ed309b2ee570c1ac98beb535fdca9139406e0")
      center;
  background-size: cover;
`;

const Home = () => {
  return (
    <Container>
      <Navbar />
    </Container>
  );
};

export default Home;
