import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// if(localStorage)
const userLocal = JSON.parse(localStorage.getItem("user"));
const tokenLocal = JSON.parse(localStorage.getItem("token"));

const initialState = {
  user: userLocal ? userLocal : null,
  token: tokenLocal ? tokenLocal : null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

//register
export const register = createAsyncThunk(
  "auth/register",
  async (user, thunkApi) => {
    // const res = await fetch("http://localhost:8080/api/users/signup", {
    const res = await fetch(
      "https://risk-management-server.onrender.com/api/users/signup",
      {
        method: "POST",
        body: JSON.stringify(user),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const result = await res.json();
    if (result?.status === "success") {
      localStorage.setItem("user", JSON.stringify(result.data.user));
      localStorage.setItem("token", JSON.stringify(result.token));
      return result;
    } else {
      return thunkApi.rejectWithValue(result.message);
    }
  }
);

//login
export const login = createAsyncThunk("auth/login", async (user, thunkApi) => {
  // const res = await fetch("http://localhost:8080/api/users/login", {
  const res = await fetch(
    "https://risk-management-server.onrender.com/api/users/login",
    {
      method: "POST",
      body: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const result = await res.json();
  if (result?.status === "success") {
    localStorage.setItem("user", JSON.stringify(result.data.user));
    localStorage.setItem("token", JSON.stringify(result.token));
    return result;
  } else {
    return thunkApi.rejectWithValue(result.message);
  }
});

//UPDATE MY PHOTO
export const updateMe = createAsyncThunk(
  "users/updateMe",
  async (data, thunkApi) => {
    // const res = await fetch("http://localhost:8080/api/users/updateMe", {
    const res = await fetch(
      "https://risk-management-server.onrender.com/api/users/updateMe",
      {
        method: "PATCH",
        body: data.data,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }
    );
    const result = await res.json();
    if (result?.status === "success") {
      localStorage.setItem("user", JSON.stringify(result.user));
      return result;
    } else {
      return thunkApi.rejectWithValue(result.message);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      localStorage.clear();
    },
  },
  extraReducers: {
    //register
    [register.pending]: (state) => {
      state.isLoading = true;
    },
    [register.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.user = action.payload.data?.user;
      state.token = action.payload.token;
    },
    [register.rejected]: (state, action) => {
      state.isLoading = false;
      state.user = null;
      state.isError = true;
      state.message = action.payload;
    },

    // login
    [login.pending]: (state) => {
      state.isLoading = true;
      state.isError = false;
      state.isSuccess = false;
    },
    [login.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.user = action.payload.data?.user;
      state.token = action.payload.token;
    },
    [login.rejected]: (state, action) => {
      state.isLoading = false;
      state.user = null;
      state.isError = true;
      state.isSuccess = false;
      state.message = action.payload;
    },

    // UPDATE MY PHOTO
    [updateMe.pending]: (state) => {
      state.isLoading = true;
      state.isError = false;
      state.isSuccess = false;
    },
    [updateMe.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.user = action.payload.user;
    },
    [updateMe.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.message = action.payload;
    },
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
