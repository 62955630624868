import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Admin from "./pages/Admin";
import NormalUser from "./pages/NormalUser";

const App = () => {
  const { user } = useSelector((state) => state.auth);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />

      {user && user.role === "admin" ? (
        <Route path="/user" element={<Admin />} />
      ) : (
        <Route path="/user" element={<NormalUser />} />
      )}
    </Routes>
  );
};

export default App;
