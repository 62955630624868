import styled from "styled-components";
import { useState } from "react";

// import CreateUser from "../components/CreateUser";
import DisastersList from "../components/DisastersList";
import Map from "../components/Map";
import Navbar from "../components/Navbar";
import RescuProfile from "../components/RescuProfile";

import imageURL from "../images/map2.PNG";
import imageURL2 from "../images/tanta.PNG";
import logo from "../images/logo.jpeg";
import cover from "../images/cover.jpeg";
import { Badge } from "@material-ui/core";
import { Notifications } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { getRescueDisasters } from "../store/disasterSlice";
import { useEffect } from "react";
import { mobile } from "../utils/responsive";

const Container = styled.div`
  /* position: relative; */
  width: 100vw;
  height: 100vh;
`;
const ConnectingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CreateUserContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`;

///////////////////////////////Profile ////////////////////////////

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url(${cover}) center;
  background-size: cover;
  width: 100vw;
`;

const UpperWrapper = styled.div`
  /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)); */
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* padding: 0 30px; */
  color: white;
`;

const LogoContainer = styled.div`
  height: 70%;
  width: 8%;
`;

const Logo = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;
const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Name = styled.h3`
  margin: 5px 0;
`;
const Email = styled.h3`
  margin: 5px 0;
`;

const NotificationContainer = styled.div`
  margin-right: 60px;
  cursor: pointer;
`;

const LowerWrapper = styled.div`
  height: 60vh;
`;

const CoverImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

////////////////////////////////////////////////

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* background-color: red; */
`;

const Title = styled.h1`
  font-weight: 500;
  border: 2px solid teal;
  border-radius: 5px;
  margin: 30px 0;
  padding: 10px 30px;
  text-align: center;
  ${mobile({ fontSize: "16px" })}
`;

const DisasterListContainer = styled.div`
  flex: 1;
  /* background-color: beige; */
  /* padding: 50px 20px 20px; */
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Message = styled.h1`
  width: 80%;
  font-weight: 500;
  border: 1px solid teal;
  border-radius: 5px;
  margin: 30px 0;
  padding: 10px 30px;
  ${mobile({ width: "60%", fontSize: "14px" })}
`;

const MapContainer = styled.div`
  flex: 1;
  /* background-color: aquamarine; */
  padding: 20px;
  /* width: 500px; */
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  height: 80%;
  width: 80%;
`;

const Admin = () => {
  const dispatch = useDispatch();

  const [isDisaster, setIsDisaster] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const { disasters } = useSelector((state) => state.disaster);
  // console.log(disasters);

  useEffect(() => {
    dispatch(getRescueDisasters(user._id));
  }, [dispatch, user._id, disasters]);

  return (
    <Container>
      <Navbar />

      <RescuProfile
        isDisaster={isDisaster}
        setIsDisaster={setIsDisaster}
        disasters={disasters}
        user={user}
      />

      {isDisaster && (
        <Wrapper>
          <Title>Disaster List</Title>
          <DisasterListContainer>
            {disasters?.map((disaster) => (
              <DisastersList
                key={disaster._id}
                disId={disaster._id}
                disaster={disaster}
                isDisaster={isDisaster}
                setIsDisaster={setIsDisaster}
                disName={disaster.name}
                disLat={disaster.lat}
                disLng={disaster.lng}
                disTime={disaster.time}
                disDate={disaster.date}
                image={imageURL}
                rescueLat={user.lat}
                rescueLng={user.lng}
              />
            ))}

            {disasters?.length === 0 && <Message>No Disaster Found</Message>}
          </DisasterListContainer>
        </Wrapper>
      )}
    </Container>
  );
};

export default Admin;
