import styled from "styled-components";
import { useState } from "react";

import Navbar from "../components/Navbar";
import UserProfile from "../components/UserProfile";
import SuccessMessage from "../components/SuccessMessage";
import { useSelector } from "react-redux";
import { mobile } from "../utils/responsive";
import { useEffect } from "react";

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
`;

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
  position: absolute;
  top: 60%;
  left: 30%;

  ${mobile({ width: "70%", left: "15%" })}
`;

const NormalUser = () => {
  const { user } = useSelector((state) => state.auth);
  const { token } = useSelector((state) => state.auth);
  const { isSuccess, isError } = useSelector((state) => state.disaster);

  const [isSent, setIsSent] = useState(false);

  useEffect(() => {}, []);
  return (
    <Container>
      <Navbar />

      <UserProfile
        isSent={isSent}
        setIsSent={setIsSent}
        user={user}
        token={token}
      />

      {isSuccess && isSent && (
        <SuccessContainer>
          <SuccessMessage
            color="teal"
            message="Your report sent successfully, Thanks!!"
          />
        </SuccessContainer>
      )}

      {isError && isSent && (
        <SuccessContainer>
          <SuccessMessage
            color="red"
            message="Something went wrong. Try again!!"
          />
        </SuccessContainer>
      )}
    </Container>
  );
};

export default NormalUser;
