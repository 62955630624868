import styled from "styled-components";
import Map from "./Map";
import { useDispatch, useSelector } from "react-redux";
import { acceptDisaster, refuseDisaster } from "../store/disasterSlice";
import { mobile } from "../utils/responsive";

const Container = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px 0;
  height: 350px;
  position: relative;
`;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Disaster = styled.div`
  border: 1px solid teal;
  border-right: none;
  border-radius: 10px 0 0 10px;
  padding: 0 10px;
  height: 80%;
  flex: 1;
  position: relative;
  ${mobile({ padding: "0 5px" })}
`;
const SubTitle = styled.p`
  font-size: 24px;
  margin: 10px 0;

  ${mobile({ fontSize: "16px", marginBottom: "20px" })}
`;

const Location = styled.p`
  font-size: 18px;
  margin: 10px 0;
  ${mobile({ fontSize: "12px" })}
`;

const ButtonsContainer = styled.div`
  position: absolute;
  width: 96%;
  left: 2%;
  bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled.button`
  background-color: ${(props) => props.color};
  color: white;
  border: none;
  border-radius: 5px;
  min-width: 25%;
  height: 50px;
  font-size: 24px;
  font-weight: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  margin: 0 10px;
  cursor: pointer;
  ${mobile({ fontSize: "14px", height: "30px" })}
`;

const MapContainer = styled.div`
  flex: 1;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid teal;
  border-radius: 0 10px 0 0;
  overflow: hidden;
`;

const DirectionButton = styled.a`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 10%;
  font-size: 19px;
  padding: 0 10px;
  background-color: #8f8ffa;
  border: 1px solid teal;
  border-top: none;
  border-radius: 0 0 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    background-color: lightblue;
  }

  ${mobile({ fontSize: "12px", height: "7%", bottom: "3%" })}
`;

const DisastersList = ({
  disId,
  isDisaster,
  setIsDisaster,
  disaster,
  disName,
  disLat,
  disLng,
  disTime,
  disDate,
  image,
  rescueLat,
  rescueLng,
}) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  const acceptHandler = async () => {
    // console.log(disaster._id);
    await dispatch(acceptDisaster(disaster._id));
    setIsDisaster(!isDisaster);
  };

  const disasterData = { disasterId: disaster._id, rescueId: user._id };

  const refuseHandler = async () => {
    await dispatch(refuseDisaster(disasterData));
    setIsDisaster(!isDisaster);
  };

  return (
    <Container>
      <Wrapper>
        <Disaster id={disId}>
          <SubTitle>
            disaster: <b style={{ color: "#EC865E" }}>{disName}</b>
          </SubTitle>
          <Location>
            latitude: <b>{disLat}</b>
          </Location>
          <Location>
            longitude: <b>{disLng}</b>
          </Location>
          <Location>
            Time: <b>{disTime}</b>
          </Location>
          <Location>
            Date: <b>{disDate}</b>
          </Location>
          <ButtonsContainer>
            <Button color="teal" onClick={acceptHandler}>
              Accept
            </Button>
            <Button color="red" onClick={refuseHandler}>
              Refuse{" "}
            </Button>
          </ButtonsContainer>
        </Disaster>
        <MapContainer>
          {/* <Map lat={disLat} lng={disLng} /> */}
          <iframe
            title="googleMap"
            width="100%"
            height="100%"
            src={`https://maps.google.com/maps?q=${disLat},${disLng}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
            frameborder="0"
            marginheight="0"
            marginwidth="0"
          ></iframe>
        </MapContainer>
      </Wrapper>
      {/* <DirectionButton
        href={`https://www.google.com/maps/@${disLat},${disLng},15z`}
        target="_blank"
      >
        Go to google map
      </DirectionButton> */}
    </Container>
  );
};

export default DisastersList;
