import { configureStore } from "@reduxjs/toolkit";
import auth from "./authSlice";
import disaster from "./disasterSlice";

const store = configureStore({
  reducer: {
    auth,
    disaster,
  },
});

export default store;
