import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  disasters: [],
  isLoadingDisasters: false,
  isSuccessDisasters: false,
  isErrorDisasters: false,
  messageDisasters: "",
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: "",
};

export const createDisaster = createAsyncThunk(
  "disasters/create",
  async (disasterDate, thunkApi) => {
    // const res = await fetch("http://localhost:8080/api/disasters", {
    const res = await fetch(
      "https://risk-management-server.onrender.com/api/disasters",
      {
        method: "POST",
        body: JSON.stringify(disasterDate),
        headers: { "Content-Type": "application/json" },
      }
    );

    const data = await res.json();
    if (data?.status === "success") {
      return data;
    } else {
      return thunkApi.rejectWithValue(data.message);
    }
  }
);

export const getRescueDisasters = createAsyncThunk(
  "disasters/rescue",
  async (rescueId, thunkApi) => {
    try {
      // const res = await fetch(
      //   `http://localhost:8080/api/disasters/rescue/${rescueId}`
      // );
      const res = await fetch(
        `https://risk-management-server.onrender.com/api/disasters/rescue/${rescueId}`
      );

      const data = await res.json();
      if (data?.status === "success") {
        return data;
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const acceptDisaster = createAsyncThunk(
  "disasters/accept",
  async (disasterId, thunkApi) => {
    // try {
    const res = await fetch(
      // `http://localhost:8080/api/disasters/accept/${disasterId}`,
      `https://risk-management-server.onrender.com/api/disasters/accept/${disasterId}`,
      {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
      }
    );

    const data = await res.json();
    if (data?.status === "success") {
      return data;
    } else {
      return thunkApi.rejectWithValue(data.message);
    }
  }
);

export const refuseDisaster = createAsyncThunk(
  "disasters/refuse",
  async (disasterData, thunkApi) => {
    const res = await fetch(
      // `http://localhost:8080/api/disasters/refuse/${disasterData.disasterId}/${disasterData.rescueId}`,
      `https://risk-management-server.onrender.com/api/disasters/refuse/${disasterData.disasterId}/${disasterData.rescueId}`,
      {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
      }
    );

    const data = await res.json();
    if (data?.status === "success") {
      return data;
    } else {
      return thunkApi.rejectWithValue(data.message);
    }
  }
);

const disasterSlice = createSlice({
  name: "disaster",
  initialState,
  extraReducers: {
    //create disaster
    [createDisaster.pending]: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = false;
    },
    [createDisaster.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.disasters = action.payload.data?.disasters;
    },
    [createDisaster.rejected]: (state, action) => {
      state.isLoading = false;
      state.disasters = null;
      state.isError = true;
      state.message = action.payload;
    },

    //get rescue disasters
    [getRescueDisasters.pending]: (state) => {
      state.isLoadingDisasters = true;
    },
    [getRescueDisasters.fulfilled]: (state, action) => {
      state.isLoadingDisasters = false;
      state.isSuccessDisasters = true;
      state.disasters = action.payload.data.disasters;
    },
    [getRescueDisasters.rejected]: (state, action) => {
      state.isLoadingDisasters = false;
      state.disasters = null;
      state.isErrorDisasters = true;
      state.messageDisasters = action.payload;
    },

    // accept Disaster
    [acceptDisaster.pending]: (state) => {
      state.isLoading = true;
    },
    [acceptDisaster.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [acceptDisaster.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    // refuse Disaster
    [refuseDisaster.pending]: (state) => {
      state.isLoading = true;
    },
    [refuseDisaster.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [refuseDisaster.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },
  },
});

export default disasterSlice.reducer;
