import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../store/authSlice";
import { mobile } from "../utils/responsive";
import { useState } from "react";

const Container = styled.div`
  height: 70px;
  background-color: teal;
`;

const Wrapper = styled.div`
  padding: 0 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${mobile({ padding: " 0 10px" })}
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const Logo = styled.h2`
  font-weight: 500;
  color: white;
  ${mobile({ fontSize: "12px" })}
`;
const Right = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const MenuItem = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: white;
  cursor: pointer;
  margin-left: 25px;
  ${mobile({ fontSize: "12px" })}
`;

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);

  const loginHandler = () => {
    navigate("/login");
  };
  const logoutHandler = () => {
    dispatch(logout());
    navigate("/login");
  };

  const createUserHandler = () => {
    navigate("/register");
  };

  return (
    <Container>
      <Wrapper>
        <Left>
          <Logo>Disaster Management Center</Logo>
        </Left>

        <Right>
          {user ? (
            <MenuItem onClick={logoutHandler}>LOG OUT</MenuItem>
          ) : (
            <>
              <MenuItem onClick={createUserHandler}>Register</MenuItem>
              <MenuItem onClick={loginHandler}>SIGN IN</MenuItem>
            </>
          )}
        </Right>
      </Wrapper>
    </Container>
  );
};

export default Navbar;
