import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";

import styled from "styled-components";

const Container = styled.div`
  width: 700px;
  height: 100%;
  margin: 100px auto;
  background-color: lightblue;
`;

const MarkerIcon = new Icon({
  iconUrl: "https://unpkg.com/leaflet@1.9.0/dist/images/marker-icon.png",
  iconSize: [25, 41],
});

const Map = ({ lat, lng }) => {
  return (
    <Container>
      <MapContainer center={[lat, lng]} zoom={10} style={{ height: "100%" }}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={[lat, lng]} icon={MarkerIcon}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </Marker>
      </MapContainer>
    </Container>
  );
};

export default Map;
